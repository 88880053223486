import React from 'react'
import poster from './img/poster.jpg';

const Container = () => {
  return (
    <div className='contains'>
      <img src={poster} />

    </div>
  )
}

export default Container