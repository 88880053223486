import React from 'react'
import Carousal from './Carousal'
import Contact from './Contact'
import About from './About'
import Slider from './Slider'
import Footer from './Footer'
import City from './City'
import Container from './Container'
import Travels from './Travels'
import Travel2 from './Travel2'
import TripTable from './TripTable'

const Home = () => {
  return (
    <div className='main'>

      <Carousal />
     
      
      <About />
      <Travels />
      <Slider />

      <Container />
      {/* <Travel2 /> */}
      <br />
      <City />
      {/* <TripTable/> */}
      <Contact />
      <Footer />
    </div>
  )
}

export default Home