import React from 'react';
import '../Vehicles/conf.css';
import Footer from '../component/Footer';

const Conference = () => {
  return (
    <>
      <div className="conference-container">
        <h2>Vehicle Quotation</h2>
        <p>To whom it may concern,</p>
        <p>
          Please accept our sincerest gratitude for showing your interest in Prism Travels.
          Post our discussion on the call attached below is the quotation of our services we provide.
        </p>
        <p>If you have any concerns or face any roadblocks, feel free to reach out. We look forward to building a beneficial relationship.</p>

        <div className="quotation-table-wrapper">
          <table className="quotation-table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Vehicle Type</th>
                <th>Hours & Kms.</th>
                <th>Charges (Rs)</th>
                <th>Extra per Hr. charges (Rs)</th>
                <th>Extra per Km charges (Rs)</th>
                <th>Night Charges (Rs)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Sedan (4+1 seater) [Dezire, Etios, Amaze, Aura]</td>
                <td>8 Hour 80Km / 12 Hour 120Km</td>
                <td>2100 / 2400</td>
                <td>140</td>
                <td>12</td>
                <td>300</td>
              </tr>
              <tr>
                <td>2</td>
                <td>SUV/MUV: Ertiga</td>
                <td>8 Hour 80Km / 12 Hour 120Km</td>
                <td>2400 / 2800</td>
                <td>175</td>
                <td>14</td>
                <td>300</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Innova</td>
                <td>8 Hour 80Km / 12 Hour 120Km</td>
                <td>2800 / 3200</td>
                <td>150</td>
                <td>16</td>
                <td>300</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Kia Carens</td>
                <td>8 Hour 80Km / 12 Hour 120Km</td>
                <td>2700 / 3000</td>
                <td>200</td>
                <td>15</td>
                <td>300</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Crysta</td>
                <td>8 Hour 80Km / 12 Hour 120Km</td>
                <td>3200 / 3600</td>
                <td>175</td>
                <td>18</td>
                <td>300</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Tempo Traveller (17-Seater)</td>
                <td>12 Hour 250Km</td>
                <td>6750</td>
                <td>500</td>
                <td>27</td>
                <td>500</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3>Terms and Conditions:</h3>
        <ul>
          <li>Night charges apply from 9:30 PM to 6:00 AM.</li>
          <li>Night charges, tolls, and parking charges are paid by the customer.</li>
          <li>Payment terms: 50% advance, 50% against invoice.</li>
        </ul>

        <p>
          Regards,<br />
          <strong>Prism Travels</strong><br />
          Shashank Upadhyay<br />
          Contact: 9696777709, 8299571745, 7905227389
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Conference;
