import React from 'react';
import Slider from 'react-slick';
import Footer from './Footer';
import mh1 from './img/v1.jpg';
import mh2 from './img/v2.jpg';
import mh3 from './img/v3.jpg';
import mh4 from './img/v4.jpg';

import google from '../component/img/goole.jpg'
// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Custom arrow components
const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} style={{ ...arrowStyles, right: '10px' }}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
    );
};

const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} style={{ ...arrowStyles, left: '10px' }}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
    );
};

// Styles for the arrows
const arrowStyles = {
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    fontSize: '24px',
    color: '#000',
    cursor: 'pointer',
    transform: 'translateY(-50%)',
    background: 'rgba(255, 255, 255, 0.7)', // Optional background for visibility
    borderRadius: '50%',
    padding: '10px',
};

const Varanasi = () => {
    // Settings for the slider
    const settings = {
        dots: true, // Display navigation dots
        infinite: true, // Infinite scrolling
        speed: 500, // Transition speed
        slidesToShow: 4, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        arrows: true, // Show left/right arrows
        nextArrow: <SampleNextArrow />, // Custom next arrow
        prevArrow: <SamplePrevArrow />, // Custom prev arrow
        responsive: [
            {
                breakpoint: 1024, // Tablet breakpoint
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // Mobile breakpoint
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480, // Extra small mobile breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div>
            <Slider {...settings}>
                <div className='image-container'>
                    <img src={mh1} alt="Mahacal 1" />
                </div>
                <div className='image-container'>
                    <img src={mh2} alt="Mahacal 2" />
                </div>
                <div className='image-container'>
                    <img src={mh3} alt="Mahacal 3" />
                </div>
                <div className='image-container'>
                    <img src={mh4} alt="Mahacal 4" />
                </div>
            </Slider>


            <div className='mahacals'>
                <h2>Varanasi  Packages From Lucknow</h2>
                <p><strong>Location:</strong> Lucknow, Gomtinagar</p>
                <p><strong>Price:</strong> ₹49,999.00</p>
                <p><strong>Duration:</strong> 4 days</p>
                <p><strong>Tour Type:</strong> Holiday Package (with stay) <br/> <br/>  <span style={{fontSize:'14px'}}> * T&C Apply</span></p>
            </div>


            <div className='packedges   '>

                <div className='newpacks'>

                    <h1>Varanasi Packages From Lucknow</h1>
                    <p>Get the best deals on Varanasi packages from Lucknow flat 20% off on your first booking.</p>

                    <p>Varanasi, a paradisiacal place steeped in history and brimming with sightseeing attractions, is sought after by travelers. An amalgamation of Portuguese and Indian cultures characterizes Goa, shaped by centuries of diverse settlements. Additionally, abundant in gorgeous beaches, legendary shacks, and vibrant parties, Goa embodies the essence of tourism. Furthermore, beyond its beaches, Goa boasts expansive forests teeming with exotic wildlife and birdlife.</p>

                    <p>Biking is the most cost-effective mode of transportation for exploring Goa’s sights without incurring extra fuel costs. Moreover, renowned for its luxury casinos, such as the ‘Dalton Royale,’ a floating casino on the Mandovi river, Goa offers extravagant entertainment options. Notably, Goa holds the distinction of being the first state in India to establish an English medium school, reflecting the influence of Portuguese, Dutch, and British settlers. Moreover, English is widely spoken in Goa, adding to its cosmopolitan allure. For comprehensive Goa travel information, travelers are encouraged to avail services from Indiator.</p>

                </div>



                <div className='newpacks googles'>

                    <div className="cards">
                        <h2>Tour Information</h2>
                        <p><strong>Max Guests:</strong> 60</p>
                        <p><strong>Min Age:</strong> 12</p>
                        <p><strong>Location:</strong> <span class="location">Varanasi </span></p>
                        <p className="languages"><strong>Languages Supported:</strong> Hindi , English</p>


                       
                    </div>

                    <div className='google'>
                        <img src={google} />
                        <button><a href='https://www.google.com/search?q=prism+travels+lucknow&oq=prism'> <i class="fa fa-google-plus"></i> Google Review</a></button>
                    </div>



                </div>


            </div>



            <Footer />
        </div>
    );
};

export default Varanasi;
