import React from 'react'
import Footer from './Footer'

const Packages = () => {
    return (
        <>
            <center><h1>Our Packages</h1></center>
            <div className='packages'>
                <h1>PRISMTRAVELS</h1>
                <h2>OFFICE:PlotNo.1594,PanditKheda,<br />KrishnaNagar,Lucknow-226023.</h2>
                <h2>E-mail:prismtravels09@gmail.com</h2>

                <table>
                    <tr>
                        <th>S.No.</th>
                        <th>Vehicle Type</th>
                        <th>Hours/GKms.</th>
                        <th>Charges (Rs)</th>
                        <th>Extra per Hr. Charges</th>
                        <th>Extra per Km Charges</th>
                        <th>Night Charges (Rs)</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Sedan (4+1-seater) [Dezire, Etios, Amaze, Aura]</td>
                        <td>8 Hour / 80 Km</td>
                        <td>1800</td>
                        <td>140</td>
                        <td>11</td>
                        <td>250</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>12 Hour / 120 Km</td>
                        <td>2200</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>SUV/MUV: Ertiga</td>
                        <td>8 Hour / 80 Km</td>
                        <td>2200</td>
                        <td>175</td>
                        <td>13</td>
                        <td>300</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>12 Hour / 120 Km</td>
                        <td>2600</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Innova</td>
                        <td>8 Hour / 80 Km</td>
                        <td>2600</td>
                        <td>150</td>
                        <td>15</td>
                        <td>300</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>12 Hour / 120 Km</td>
                        <td>3000</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Kia Carens</td>
                        <td>8 Hour / 80 Km</td>
                        <td>2500</td>
                        <td>200</td>
                        <td>14</td>
                        <td>300</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>12 Hour / 120 Km</td>
                        <td>2800</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Crysta</td>
                        <td>8 Hour / 80 Km</td>
                        <td>3000</td>
                        <td>175</td>
                        <td>17</td>
                        <td>300</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>12 Hour / 120 Km</td>
                        <td>3400</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Tempo Traveller (17-Seater)</td>
                        <td>12 Hour / 250 Km per/day Journey</td>
                        <td>8500</td>
                        <td>500</td>
                        <td>27</td>
                        <td>500</td>
                    </tr>
                </table>

                <h1>TERMS AND CONDITIONS:-</h1>
                <p>1.	Night will be charges on before 06:00AM To 9:30PM after.</p>
                <p>2.	Night Charges,Toll and Parking charges paid by your side.</p>
                <p>3.	All prices are net.</p>
                <p>4.	Payment:50% Advanceand 50% against invoice.</p>

<br/>


                <p><b> Shashank Upadhyay</b></p>
                <p>Mob: 9696777709</p>
                <p>8299571745</p>
                <p>7905227389</p>

            </div>

            <Footer />
        </>
    )
}

export default Packages