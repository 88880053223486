import React, { useEffect, useState } from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useParams, useNavigate } from 'react-router-dom';

const Details = () => {
  const [getuserdata, setUserdata] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const getdata = async () => {
    try {
      const res = await fetch(`/getuser/${id}`);
      if (!res.ok) {
        throw new Error('User not found');
      }
      const data = await res.json();
      setUserdata(data);
      console.log("Data has been retrieved");
    } catch (error) {
      console.error("Error fetching data:", error.message);
      alert("No record available");
      navigate('/');
    }
  };

  useEffect(() => {
    getdata();
  }, [id]);

  const deleteUser = async (id) => {
    try {
      const res = await fetch(`/deleteuser/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!res.ok) {
        throw new Error('Failed to delete user');
      }
      console.log("User Deleted");
      navigate('/');
    } catch (error) {
      console.error("Error deleting user:", error.message);
      alert("Failed to delete user");
    }
  };

  if (!getuserdata) {
    return null; // Render nothing until data is fetched
  }

  return (
    <div className="container mt-3">
      <center>
        <h1 style={{ fontWeight: '400' }}>Booking Details</h1>
      </center>
      <Card style={{ maxWidth: '600px' }}>
        <CardContent style={{ maxWidth: '600px' }}>
          <div className="row">
            <div className='left-view col-lg-6 col-md-6 col-12'>
            <p className='mt-3'><WorkIcon /> <b> UserName</b> - {getuserdata.username}</p>
              <p className='mt-3'><DriveFileRenameOutlineIcon /><b> PickUp City</b> - {getuserdata.pickupcity}</p>
              <p className='mt-3'><CalendarMonthIcon /><b> Drop City</b> - {getuserdata.dropcity}</p>
              <p className='mt-3'><MailOutlineIcon /> <b> Date & Time</b> - {getuserdata.time}</p>
              <p className='mt-3'><WorkIcon /> <b> Email</b> - {getuserdata.email}</p>
            </div>
            <div className='right-view col-lg-6 col-md-6 col-12'>
            <p className='mt-3'><WorkIcon /> <b> Contact No</b> - {getuserdata.mobile}</p>
              <p className='mt-4'><PhoneAndroidIcon /><b> Our Vehicle</b> - {getuserdata.vehicle}</p>
              <p className='mt-4'><LocationOnIcon /><b> Our Trip</b> - {getuserdata.trip}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Details;
