import React, { useState } from 'react';

const Contact = () => {
    // State to manage form input values
    const [inpval, setInp] = useState({
        username: "", 
        pickupcity: "",
        dropcity: "",
        time: "",
        mobile: "",
        vehicle: "",
        email: "",
        trip: ""
    });

    // Function to handle input changes
    const setData = (e) => {
        const { name, value } = e.target;
        setInp(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Function to redirect to WhatsApp with form data
    const redirectToWhatsApp = () => {
        const { username, pickupcity, dropcity, time, mobile, email, vehicle, trip } = inpval;

        // Constructing the message
        const message = `Name: ${username}PickUp City: ${pickupcity}Drop City: ${dropcity}Date & Time: ${time}Mobile: ${mobile}Email: ${email}Vehicle: ${vehicle}Trip: ${trip}`;
        
        // Encoding the message for the URL
        const encodedMessage = encodeURIComponent(message);
        
        // WhatsApp URL with the encoded message
        const whatsappUrl = `https://wa.me/918299571745?text=${encodedMessage}`;
        
        // Redirect to WhatsApp
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className='container'>
            <form className='mt-1'>
                <div className='row'>
                    <div className="mb-3 col-lg-6 col-12">
                        <label htmlFor="username" className="form-label">Username:</label>
                        <input 
                            type="text" 
                            value={inpval.username} 
                            onChange={setData} 
                            name="username" 
                            className="form-control" 
                            id="username" 
                            required 
                        />
                    </div>

                    <div className="mb-3 col-lg-6 col-12">
                        <label htmlFor="pickupcity" className="form-label">Pick Up City:</label>
                        <input 
                            type="text" 
                            value={inpval.pickupcity} 
                            onChange={setData} 
                            name="pickupcity" 
                            className="form-control" 
                            id="pickupcity" 
                        />
                    </div>

                    <div className="mb-3 col-lg-6 col-12">
                        <label htmlFor="dropcity" className="form-label">Drop City:</label>
                        <input 
                            type="text" 
                            value={inpval.dropcity} 
                            onChange={setData} 
                            name="dropcity" 
                            className="form-control" 
                            id="dropcity" 
                        />
                    </div>

                    <div className="mb-3 col-lg-6 col-12">
                        <label htmlFor="time" className="form-label">Date & Time:</label>
                        <input 
                            type="datetime-local" 
                            value={inpval.time} 
                            onChange={setData} 
                            name="time" 
                            className="form-control" 
                            id="time" 
                        />
                    </div>

                    <div className="mb-3 col-lg-6 col-12">
                        <label htmlFor="mobile" className="form-label">Contact No.:</label>
                        <input 
                            type="text" 
                            value={inpval.mobile} 
                            onChange={setData} 
                            name="mobile" 
                            className="form-control" 
                            id="mobile" 
                        />
                    </div>

                    <div className="mb-3 col-lg-6 col-12">
                        <label htmlFor="vehicle" className="form-label">Select Vehicle:</label>
                        <select 
                            value={inpval.vehicle} 
                            onChange={setData} 
                            name="vehicle" 
                            className="form-control" 
                            id="vehicle"
                        >
                            <option value="">- Select Vehicle -</option>
                            <option value="Innova Crysta 7 Seater">Innova Crysta 7 Seater</option>
                            <option value="Kia carens 7 Seater">Kia carens 7 Seater</option>
                            <option value="Ertiga 7 Seater">Ertiga 7 Seater</option>
                            <option value="Etios Dzire 5 Seater">Etios Dzire 5 Seater</option>
                            <option value="Amaze 5 Seater">Amaze 5 Seater</option>
                            <option value="Hyundai Aura 5 Seater">Hyundai Aura 5 Seater</option>
                            <option value="Tempo Traveller (Force urbania)">Tempo Traveller (Force urbania)</option>
                        </select>
                    </div>

                    <div className="mb-3 col-lg-6 col-12">
                        <label htmlFor="email" className="form-label">Email:</label>
                        <input 
                            type="text" 
                            value={inpval.email} 
                            onChange={setData} 
                            name="email" 
                            className="form-control" 
                            id="email" 
                        />
                    </div>

                    <div className="mb-3 col-lg-6 col-12">
                        <label htmlFor="trip" className="form-label">Select Trip:</label>
                        <select 
                            value={inpval.trip} 
                            onChange={setData} 
                            name="trip" 
                            className="form-control" 
                            id="trip"
                        >
                            <option value="">- Select Trip -</option>
                            <option value="Birthday Party">Birthday Party</option>
                            <option value="Wedding Party">Wedding Party</option>
                            <option value="Ayodhya Darshan">Ayodhya Darshan</option>
                            <option value="Manali Trip">Manali Trip</option>
                        </select>
                    </div>

                    <div className="mb-3 col-12">
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            onClick={redirectToWhatsApp}
                        >
                            Submit
                        </button>


                        <br/> <br/>  <span style={{fontSize:'14px' ,fontStyle:'initial '}}>  * T&C Apply</span>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Contact;
