import React from 'react'
import Ramji from './img/Ram.jpg'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import city1 from './img/Luckow.jpg';
import city2 from './img/manali.jpg';
import city3 from './img/jaipur.jpg';
import city4 from './img/mumbai.jpg';
import Container from './Container';

const Gallery = () => {
    return (
        <>

            <center><h2>Our Gallery</h2></center>

            <div className='city'>

                <div className='city2'>
                    <img src={city1} />
                </div>

                <div className='city2'>
                    <img src={city2} />
                </div>

            </div>

            <div className='city'>

                <div className='city2'>
                    <img src={city3} />
                </div>

                <div className='city2'>
                    <img src={city4} />
                </div>

            </div>
            <br />


            <Container />
            <Footer />

        </>
    )
}

export default Gallery