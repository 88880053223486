import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';


const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" >
                        <b>PRISM TRAVELS</b>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={expanded} aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>HOME</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Section" className="nav-link" onClick={handleNavItemClick}>ABOUT</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">OUR TRIP</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                    <li><Link to="/Luxury" className="dropdown-item" onClick={handleNavItemClick}>Luxury Car for Wedding</Link></li>
                                    <li><Link to="/Uttrakhand" className="dropdown-item" onClick={handleNavItemClick}>Lucknow to Uttrakhand</Link></li>
                                    <li><Link to="/Ayoudhya" className="dropdown-item" onClick={handleNavItemClick}>Ayodhya Darshan</Link></li>
                                    <li><Link to="/Varanasi" className="dropdown-item" onClick={handleNavItemClick}>Varanasi </Link></li>
                                    <li><Link to="/Conference" className="dropdown-item" onClick={handleNavItemClick}>Luxury car for Conferences/seminars </Link></li>

                                </ul>
                            </li>


                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false"> OUR VEHICLES</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                    <li><Link to="/Innovacrysta" className="dropdown-item" onClick={handleNavItemClick}>Innova Crysta 7 Seater</Link></li>
                                    <li><Link to="/Kiacaren" className="dropdown-item" onClick={handleNavItemClick}>Kia carens 7 Seater</Link></li>
                                    <li><Link to="/Irtiga" className="dropdown-item" onClick={handleNavItemClick}>Ertiga 7 Seater</Link></li>
                                    <li><Link to="/Etios" className="dropdown-item" onClick={handleNavItemClick}>Etios Dzire 5 Seater</Link></li>
                                    <li><Link to="/Amaze" className="dropdown-item" onClick={handleNavItemClick}>Amaze 5 Seater</Link></li>
                                    <li><Link to="/Tempoo" className="dropdown-item" onClick={handleNavItemClick}>Tempo Traveller (Force urbania)</Link></li>

                                </ul>
                            </li>


                            <li className="nav-item">
                                <Link to="/Packages" className="nav-link" onClick={handleNavItemClick}> PACKAGES</Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/Gallery" className="nav-link" onClick={handleNavItemClick}>GALLERY</Link>
                            </li>



                            {/* <li className="nav-item">
                                <Link to="/Data" className="nav-link" onClick={handleNavItemClick}>VIEW DATA </Link>
                            </li> */}



                            <li className="nav-item">
                                <Link to="/Contact" className="nav-link" onClick={handleNavItemClick}> BOOK VEHICLES</Link>
                            </li>

                        </ul>


                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
