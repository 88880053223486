import React, { useState } from 'react';



const Head = () => {

    return (
        <div className='head'>
            <div className='head2'>
                <p>Agency Director:  +91 9696777709 ,  +91 8299571745 </p>
            </div>
            
            <div className='head2'>
                <a href="#" class="facebook2"><i class="fa fa-facebook"></i></a>
                <a href="#" class="facebook2"><i class="fa fa-instagram"></i></a>
                <a href="#" class="facebook2"><i class="fa fa-youtube-play"></i></a>
            </div>

            <div className='head2'>
                <p> Near Saubhagya Lawn  Krishna Nagar Lucknow </p>
            </div>


        </div>
    );
};

export default Head;
