import React from 'react'
import city1 from './img/Luckow.jpg';
import city2 from './img/manali.jpg';
import city3 from './img/jaipur.jpg';
import city4 from './img/mumbai.jpg';
import { Link } from 'react-router-dom'

const City = () => {
    return (
        <>
            <div className='city'>



                <div className='city2'>
                    <Link to='/Mahacal'>
                        <h1>MAHAKAAL </h1>
                        <img src={city1} />
                    </Link>
                </div>



                <div className='city2'>
                <Link to='/Varanasi'>
                    <h1>VARANASI </h1>
                    <img src={city2} />
                    </Link>
                </div>

            </div>

            <div className='city'>

                <div className='city2'>
                <Link to='/Ayoudhya'>
                    <h1>AYODHYA  </h1>
                    <img src={city3} />
                    </Link>
                </div>

                <div className='city2'>
                <Link to='/Kedardham'>
                    <h1>KEDARNATH </h1>
                    <img src={city4} />
                    </Link>
                </div>

            </div>


        </>
    )
}

export default City