import React from 'react'
import van1 from '../component/img/kia.jpg';
import van2 from '../component/img/kia2.jpg';
import van3 from '../component/img/kia3.jpg';
import Footer from '../component/Footer';

const Kiacaren = () => {
    return (
      
            <div className="innovacrysta-container">
                <div className="image-gallery">
                    <img src={van1} alt="Innova Crysta 1" className="responsive-img" />
                    <img src={van2} alt="Innova Crysta 2" className="responsive-img" />
                    <img src={van3} alt="Innova Crysta 3" className="responsive-img" />
                </div>
                <Footer />
            </div>
      
    )
}

export default Kiacaren
