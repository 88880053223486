import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <div className='footer'>

                <div className='foot'>
                    <h1>Location</h1>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14245.70226882719!2d80.8592705!3d26.7945749!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bffd56f364803%3A0x124dd339e7525554!2sPrism%20travels!5e0!3m2!1sen!2sin!4v1721108751342!5m2!1sen!2sin" ></iframe>
                </div>

                <div className='foot'>
                    <h1>  Our Vehicles </h1>
                    <Link to='/Innovacrysta' className='best'> <p>Innova Crysta 7 Seater</p></Link>
                    <Link to='/Kiacaren' className='best'> <p>Kia carens 7 Seater</p></Link>
                    <Link to='/Irtiga' className='best'> <p>Ertiga 7 Seater</p></Link>
                    <Link to='/Etios' className='best'> <p>Etios Dzire 5 Seater</p></Link>
                    <Link to='/Amaze' className='best'> <p>Amaze 5 Seater</p></Link>
                    <Link to='/Tempoo' className='best'> <p>Tempo Traveller (Force urbania)</p></Link>

                </div>

                <div className='foot'>
                    <h1>Our Trip   </h1>
                  
                    <Link to='/Luxury' className='best'> <p>Luxury Car for Wedding  </p></Link>
                    <Link to='/Uttrakhand' className='best'> <p> Lucknow to Uttrakhand </p></Link>
                    <Link to='/Ayoudhya' className='best'> <p> Lucknow to Ayodhya </p></Link>
                    <Link to='/Varanasi' className='best'> <p>Lucknow to Varanasi</p></Link>
                  
                </div>



                <div className='foot'>
                    <h1>Reach Us </h1>
                    <p>Call: +91 9696777709</p>
                    <p>Call: +91 8299571745</p>
                    <p>prismenterprises0524@gmail.com</p>
                    <p>
                        Near Saubhagya Lawn  Krishna Nagar Lucknow </p>
                </div>

            </div>

            <div className='disclamer'>
                <p>@ 2024 Prism Travells   Designed by Creative Digital World </p>
            </div>


        </>
    )
}

export default Footer