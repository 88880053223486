import React from 'react';
import Slider from "react-slick";
import van1 from './img/ennova crysrta.jpg';
import van2 from './img/carens.jpg';
import van3 from './img/ertiga7.jpg';
import van4 from './img/eticios.jpg';
import van5 from './img/amaze5.jpg';
import van6 from './img/aura.jpg';
import van7 from './img/force.jpg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,       // Enable autoplay
    autoplaySpeed: 3000,  // Speed of autoplay in milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          autoplay: true,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        }
      }
    ]
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img src={van1} alt="Van 1" />
          
        </div>
        <div>
          <img src={van2} alt="Van 2" />
        </div>
        <div>
          <img src={van3} alt="Van 3" />
        </div>
        <div>
          <img src={van4} alt="Van 4" />
        </div>
        <div>
          <img src={van5} alt="Van 5" />
        </div>
        <div>
          <img src={van6} alt="Van 6" />
        </div>

        <div>
          <img src={van7} alt="Van 6" />
        </div>
      </Slider>
    </div>
  );
}

export default ImageSlider;
