import React from 'react'
import van1 from '../component/img/amaze5.jpg';
import van2 from '../component/img/amaze6.jpg';
import van3 from '../component/img/amaze7.jpg';
import Footer from '../component/Footer';

const Amaze = () => {
  return (
    <div className="innovacrysta-container">
      <div className="image-gallery">
        <img src={van1} alt="Innova Crysta 1" className="responsive-img" />
        <img src={van2} alt="Innova Crysta 2" className="responsive-img" />
        <img src={van3} alt="Innova Crysta 3" className="responsive-img" />
      </div>
      <Footer />
    </div>
  )
}

export default Amaze
