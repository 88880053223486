// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import Navbar from './component/Navbar';
import Home from './component/Home.js';
import Head from './component/Head.js';
import Contact from './component/Contact.js';
import Register from './component/Register.js'
import Ayoudhya from './component/Ayoudhya.js'


import Data from './data/Data.js';
import Details from './data/Details.js';
import Section from './component/Section.js';
import Gallery from './component/Gallery.js';
import Packages from './component/Packages.js';
import Mahacal from './component/Mahacal.js';
import Varanasi from './component/Varanasi.js';
import Kedardham from './component/Kedardham.js';
import Uttrakhand from './component/Uttrakhand.js';
import Innovacrysta from './Vehicles/Innovacrysta.js';
import Kiacaren from './Vehicles/Kiacaren.js';
import Irtiga from './Vehicles/Irtiga.js';
import Etios from './Vehicles/Etios .js';
import Amaze from './Vehicles/Amaze.js';
import Tempoo from './Vehicles/Tempoo.js';
import Luxury from './Vehicles/Luxury.js';
import Conference from './Vehicles/Conference.js';


function App() {


  return (
    <div className='main'>

      <Router>
     
        <Head />
        <Navbar />
        <Routes >
          <Route path="*" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Section" element={<Section />} />
          <Route path="/Packages" element={<Packages />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Mahacal" element={<Mahacal />} />
          <Route path="/Varanasi" element={<Varanasi />} />
          <Route path="/Ayoudhya" element={<Ayoudhya />} />
          <Route path="/Kedardham" element={<Kedardham />} />
          <Route path="/Uttrakhand" element={<Uttrakhand />} />
          <Route path="/Innovacrysta" element={<Innovacrysta />} />
          <Route path="/Kiacaren" element={<Kiacaren />} />
          <Route path="/Irtiga" element={<Irtiga />} />
          <Route path="/Etios" element={<Etios />} />
          <Route path="/Amaze" element={<Amaze />} />
          <Route path="/Tempoo" element={<Tempoo />} />
          <Route path="/Luxury" element={<Luxury />} />


        
          <Route path="/Data" element={<Data />} />
          <Route path="data/view/:id" element={<Details />} />
          <Route path="/Conference" element={<Conference />} />

        </Routes>
      </Router>

    </div>
  );
}

export default App;
