import React, { useState } from 'react';
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';
import Footer from './Footer';

const Register = () => {
    const [inpval, setInp] = useState({
        pickupcity: "",
        dropcity: "",
        time: "",
        mobile: "",
        vehicle: "",
        email: "",
        trip: ""
    });

    const setData = (e) => {
        const { name, value } = e.target;
        setInp(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const sendEmail = (e) => {
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAILJS_USER_ID)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    const addinpdata = async (e) => {
        e.preventDefault();
        const { pickupcity, dropcity, time, mobile, email, vehicle, trip } = inpval;

        const res = await fetch("/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                pickupcity, dropcity, time, mobile, email, vehicle, trip
            })
        });

        if (res.ok) {
            const data = await res.json();
            Swal.fire({
                icon: 'success',
                title: 'Book Appointment Successfully',
                showConfirmButton: false,
                timer: 1000
            });

            console.log("Data Saved");
            setInp({
                pickupcity: "",
                dropcity: "",
                time: "",
                mobile: "",
                vehicle: "",
                email: "",
                trip: ""
            });

            // Send email after successful form submission
            sendEmail(e);
        } else {
            const errorData = await res.json();
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorData.message || 'Something went wrong!'
            });
            console.log("Error", errorData);
        }
    };

    return (

        <>

<center><h2>Book Trip</h2></center>
            <div className='container'>
                <form className='mt-1' onSubmit={addinpdata}>
                    <div className='row'>
                        <div className="mb-3 col-lg-6 col-12">
                            <label htmlFor="pickupcity" className="form-label">Pick Up City:</label>
                            <input type="text" value={inpval.pickupcity} onChange={setData} name="pickupcity" className="form-control" id="pickupcity" />
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label htmlFor="dropcity" className="form-label">Drop City:</label>
                            <input type="text" value={inpval.dropcity} onChange={setData} name="dropcity" className="form-control" id="dropcity" />
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label htmlFor="time" className="form-label">Date & Time:</label>
                            <input type="datetime-local" value={inpval.time} onChange={setData} name="time" className="form-control" id="time" />
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label htmlFor="mobile" className="form-label">Contact No.:</label>
                            <input type="text" value={inpval.mobile} onChange={setData} name="mobile" className="form-control" id="mobile" />
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label htmlFor="vehicle" className="form-label">Select Vehicle:</label>
                            <select value={inpval.vehicle} onChange={setData} name="vehicle" className="form-control" id="vehicle">
                                <option value="">- Select Vehicle -</option>
                                <option value="Audi A6">Audi A6</option>
                                <option value="Mercedes E Class">Mercedes E Class</option>
                                <option value="Jaguar XF">Jaguar XF</option>
                                <option value="Maruti Suzuki Dzire">Maruti Suzuki Dzire</option>
                                <option value="BMW">BMW</option>
                                <option value="Toyota Fortuner">Toyota Fortuner</option>
                                <option value="Maruti Ertiga">Maruti Ertiga</option>
                                <option value="Honda City">Honda City</option>
                                <option value="Honda Amaze">Honda Amaze</option>
                                <option value="Maruti Suzuki Ciaz">Maruti Suzuki Ciaz</option>
                                <option value="Tempo Traveller (26, 17, 13 Seater)">Tempo Traveller (26, 17, 13 Seater)</option>
                                <option value="Bus (35, 55 Seater)">Bus (35, 55 Seater)</option>
                            </select>
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input type="text" value={inpval.email} onChange={setData} name="email" className="form-control" id="email" />
                        </div>

                        <div className="mb-3 col-lg-6 col-12">
                            <label htmlFor="trip" className="form-label">Select Trip:</label>
                            <select value={inpval.trip} onChange={setData} name="trip" className="form-control" id="trip">
                                <option value="">- Select Trip -</option>
                                <option value="Birthday Party">Birthday Party</option>
                                <option value="Wedding Party">Wedding Party</option>
                                <option value="Ayodhya Darshan">Ayodhya Darshan</option>
                                <option value="Manali Trip">Manali Trip</option>
                            </select>
                        </div>

                        <div className="mb-3 col-12">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>


            <Footer />

        </>
    );
};

export default Register;
