import React from 'react'
import { NavLink } from 'react-router-dom'

const Travels = () => {
    return (
        <div className='travels'>

            <h1>Best Travel Agency In Lucknow</h1>
            <p>For years, we’ve been the go-to travel agency for Lucknow’s discerning travelers. Our deep local roots combined with global connections allow us to offer unmatched expertise and the best travel solutions tailored to your needs.
            </p>

            <p>No two travelers are alike, and neither are the trips we plan. Our travel consultants take the time to understand your preferences, interests, and budget to craft an itinerary that is uniquely yours. From family vacations to solo adventures, we design experiences that reflect your personal style.</p>

            {/* <h1>BEST LUCKNOW TO NEPAL TOUR PACKAGES</h1>

            <p>Discover Nepal's beauty with our Highly Recommended Deals. Holiday Travels, your trusted travel agent in Lucknow, offers top-rated tours to Kathmandu, Pokhara, Ayodhya, Muktinath, and Annapurna. Your dream Nepal adventure starts here Lucknow to Nepal! Explore our Nepal tour packages from Lucknow and choose the perfect itinerary for an unforgettable experience. Whether you're fascinated by the cultural richness of Kathmandu, the serene beauty of Pokhara, or the spiritual journey to Muktinath, we have curated tours that cater to your preferences. Book with Holiday Travels for an exceptional journey from Lucknow to Nepal with our highly recommended deals and unmatched services.</p> */}
            <center>

                <NavLink to='/Packages'> Packages</NavLink>
            </center>





        </div>
    )
}

export default Travels
