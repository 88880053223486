import React, { useEffect, useState } from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

const Data = () => {
    const [getuserdata, setuserdata] = useState([]);

    // Function to fetch data from server
    const getdata = async () => {
        try {
            const res = await fetch("/getdata", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const data = await res.json();

            if (!data || res.status !== 200) {
                throw new Error("No records available");
            }

            setuserdata(data);
            console.log("Data has been retrieved successfully:", data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            });
        }
    };

    // Load data on component mount
    useEffect(() => {
        getdata();
    }, []);

    // Function to delete a user
    const deleteuser = async (id) => {
        try {
            const res = await fetch(`/deleteuser/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const deletedata = await res.json();

            if (!res.ok) {
                throw new Error("Failed to delete user");
            }

            console.log("User Deleted:", deletedata);
            Swal.fire({
                icon: 'success',
                title: 'User Deleted Successfully',
                showConfirmButton: false,
                timer: 1000
            });
            
            // Refresh data after deletion
            getdata();
        } catch (error) {
            console.error("Error deleting user:", error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            });
        }
    };

    return (
        <div className='container'>
            <center>
                <h2 className="card-title">Prism Travels</h2>
                <h5 className="card-title">Bookings Leads</h5>
            </center>
            <br/>
            <table className="table table-bordered">
                <thead>
                    <tr className="table-dark" style={{ textAlign: 'center' }}>
                        <th scope="col">Booking Id</th>
                        <th scope="col">User Name</th>
                        <th scope="col">PickUp City</th>
                        <th scope="col">Drop City</th>
                        <th scope="col">Date & Time</th>
                        <th scope="col">Contact No</th>
                        <th scope="col">User Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {getuserdata.map((element, id) => (
                        <tr key={element._id}>
                            <td>{id + 1}</td>
                            <td>{element.username}</td>
                            <td>{element.pickupcity}</td>
                            <td>{element.dropcity}</td>
                            <td>{element.time}</td>
                            <td>{element.mobile}</td>
                            <td className='d-flex justify-content-between'>
                                <NavLink to={`view/${element._id}`} className='btn btn-success'>
                                    <RemoveRedEyeIcon /> View Detail
                                </NavLink>
                                {/* Uncomment when edit functionality is implemented */}
                                {/* <NavLink to={`edit/${element._id}`} className='btn btn-primary'>
                                    <CreateIcon /> Edit
                                </NavLink> */}
                                <button className='btn btn-danger' onClick={() => deleteuser(element._id)}>
                                    <DeleteOutlineIcon /> Delete Lead
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Data;
