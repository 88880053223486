import React from 'react'

import van1 from '../component/img/ertiga7.jpg';
import van2 from '../component/img/ertiga72.jpg';
import van3 from '../component/img/ertiga73.jpg';
import Footer from '../component/Footer';

const Irtiga = () => {
  return (
    <div className="innovacrysta-container">
    <div className="image-gallery">
      <img src={van1} alt="Innova Crysta 1" className="responsive-img" />
     
      <img src={van3} alt="Innova Crysta 3" className="responsive-img" />
    </div>
    <Footer />
  </div>
  )
}

export default Irtiga
