import React from 'react'

import Ramji from './img/Ram.jpg'
import { Link } from 'react-router-dom'
import Footer from './Footer'

const Section = () => {
    return (
        <>

            <center><h2>About Us </h2></center>
            
            <div className='about'>

                <div className='about2'>
                    <img src={Ramji} />
                </div>

                <div className='about2'>
                    <h1>WELCOME TO PRISM TRAVELS
                    </h1>
                    <p>At Prism Travels, we believe that every journey tells a story. With a passion for exploration and a commitment to excellent service, we offer more than just travel packages—we create experiences that leave a lasting impression. Whether you're seeking an adrenaline-pumping adventure or a relaxing getaway, our dedicated team is here to craft personalized journeys tailored to your preferences.</p>
                    <p>Our mission is simple: to inspire and facilitate unforgettable travel experiences, ensuring every detail exceeds expectations. From luxurious accommodations to off-the-beaten-path excursions, Prism Travels is your partner in discovering the world's wonders, one journey at a time.</p>
                    <p>Join us and discover the difference of traveling with Prism Travels—where every trip is a masterpiece of exploration and discovery.</p>

                    <nav >
                        <button class="btn btn-primary">Read More</button>
                    </nav>

                </div>

            </div>

            <Footer />

        </>
    )
}

export default Section