import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

import Video from './img/vid1.mp4';
import Video2 from './img/vid2.jpg';
import Video3 from './img/vid3.mp4';

import rams from './img/ayodhya.jpg';
import imam from './img/imam.jpg';
import chandrika from './img/chandra.jpg';
import lucknow from './img/lko.jpg';


const Carousal = () => {


    return (
        <Carousel>

            <Carousel.Item >
                <img src={lucknow} />
                <Carousel.Caption>
                    <h3>PRISM TRAVELS</h3>
                    <p>Lucknow Tourism</p>
                </Carousel.Caption>
            </Carousel.Item>


            <Carousel.Item >
                <img src={rams} />
                <Carousel.Caption>
                    <h3>PRISM TRAVELS</h3>
                    <p>Ayodhya Ram Mandir</p>
                </Carousel.Caption>
            </Carousel.Item>


            <Carousel.Item >
                <img src={imam} />

                <Carousel.Caption>
                    <h3>PRISM TRAVELS</h3>
                    <p>Imambada Lucknow</p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item >
                <img src={chandrika} />

                <Carousel.Caption>
                    <h3>PRISM TRAVELS</h3>
                    <p>Chandrika Devi Temple Lucknow</p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <video className="d-block w-100" autoPlay loop muted>
                    <source src={Video} type="video/mp4" />

                </video>
                <Carousel.Caption>
                    <h3>PRISM TRAVELS</h3>
                    <p>No matter where in the world you want to go, we can help get you there</p>
                </Carousel.Caption>
            </Carousel.Item>


            <Carousel.Item >
                <img src={Video2} />

                <Carousel.Caption>
                    <h3>PRISM TRAVELS</h3>
                    <p>Beauty, Charm, And Adventure.</p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <video className="d-block w-100" autoPlay loop muted>
                    <source src={Video3} type="video/mp4" />

                </video>
                <Carousel.Caption>
                    <h3>PRISM TRAVELS</h3>
                    <p>Exclusive Holidays For The Single Traveler.</p>
                </Carousel.Caption>
            </Carousel.Item>


        </Carousel>
    )
}

export default Carousal